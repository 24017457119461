import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ScrollAnimation from "react-animate-on-scroll";
import Image from "next/image";
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, errorInfo: "", error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    this.setState({ errorInfo });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Grid
          container
          alignItems="flex-end"
          justifyContent="center"
          flexDirection="row"
          sx={{
            backgroundColor: "#fdfde0",
            width: "100%",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <Grid
            item
            xs={12}
            container
            flexDirection="column"
            alignItems="center"
            sx={{ my: 2 }}
          >
            <Typography variant="h3" sx={{ color: "#d32f2f" }}>
              Error Occured
            </Typography>
            <Typography variant="body1">
              {this.state.error && this.state.error.message}
            </Typography>

            <Button
              variant="contained"
              onClick={() => window.location.reload()}
            >
              Try Again
            </Button>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={4}>
              <ScrollAnimation
                animateIn="animate__slideInUp"
                animateOut="animate__slideOutDown"
                delay={0}
              >
                <Image
                  src={
                    "https://frogo-strapi.s3.eu-central-1.amazonaws.com/frogo_transparent_981c528d1d.png"
                  }
                  width={480}
                  height={480}
                />
              </ScrollAnimation>
            </Grid>
            <Grid item xs={4}>
              <ScrollAnimation
                animateIn="animate__slideInUp"
                animateOut="animate__slideOutDown"
                delay={700}
              >
                <Image
                  src={
                    "https://frogo-strapi.s3.eu-central-1.amazonaws.com/frogo_transparent_981c528d1d.png"
                  }
                  width={480}
                  height={480}
                />
              </ScrollAnimation>
            </Grid>
            <Grid item xs={4}>
              <ScrollAnimation
                animateIn="animate__slideInUp"
                animateOut="animate__slideOutDown"
                delay={300}
              >
                <Image
                  src={
                    "https://frogo-strapi.s3.eu-central-1.amazonaws.com/frogo_transparent_981c528d1d.png"
                  }
                  width={480}
                  height={480}
                />
              </ScrollAnimation>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
