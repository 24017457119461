import "@rainbow-me/rainbowkit/styles.css";
import App from "next/app";
import Head from "next/head";
import { getGlobalData } from "utils/api";
import { Share_Tech_Mono } from "next/font/google";
import "@/styles/index.css";
import "animate.css/animate.min.css";
import theme from "../utils/theme";
import { ThemeProvider } from "@mui/material/styles";
import useGoogleAnalytics from "../utils/google-analytics";
import ErrorBoundary from "@/components/error-boundary";

const shareTechMono = Share_Tech_Mono({
  weight: "400",
  style: "normal",
  display: "auto",
  preload: false,
});

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  const { injectableScript } = useGoogleAnalytics();

  return (
    <>
      {/* Favicon */}
      <Head>
        <style jsx global>{`
          html {
            font-family: ${shareTechMono.style.fontFamily};
          }
        `}</style>
      </Head>
      {injectableScript}
      {/* Display the content */}
      <ThemeProvider theme={theme}>
        <main className={shareTechMono.className}>
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
        </main>
      </ThemeProvider>
    </>
  );
};

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So [[...slug]] pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
MyApp.getInitialProps = async (appContext) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  const globalLocale = await getGlobalData(appContext.router.locale);

  return {
    ...appProps,
    pageProps: {
      global: globalLocale.data,
    },
  };
};

export default MyApp;
