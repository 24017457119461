import { createTheme } from "@mui/material/styles";
import { Share_Tech_Mono } from "next/font/google";

const shareTechMono = Share_Tech_Mono({
  weight: "400",
  style: "normal",
  display: "auto",
  preload: false,
});

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: shareTechMono.style.fontFamily,
  },
});

export default theme;
