import React, { useEffect } from "react";
import Script from "next/script";
import { useRouter } from "next/router";

const gaMeasurementId = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

export default function useGoogleAnalytics() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      window.gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (router.query && window.gtag) {
      const { utm_id, utm_campaign, utm_medium, utm_source } = router.query;

      if (utm_campaign && utm_source && utm_medium && utm_id) {
        window.gtag("set", {
          campaign_id: router.query?.utm_id || "",
          campaign_source: router.query?.utm_source || "",
          campaign_medium: router.query?.utm_medium || "",
          campaign_name: router.query?.utm_campaign || "",
        });
      }
    }
  }, [router.query]);

  const injectableScript = (
    <React.Fragment>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`}
      />
      <Script strategy="afterInteractive" id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', '${gaMeasurementId}');
        `}
      </Script>
    </React.Fragment>
  );

  return { injectableScript };
}
